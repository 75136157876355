import * as React from "react"
import {
  ChakraProvider,
  Box,
  VStack,
  theme,
  Heading,
  Tabs,
  TabList,
  Tab,
  TabPanels,
  TabPanel,
  HStack,
  TableContainer,
  Table,
  TableCaption,
  Thead,
  Tr, Th, Tbody, Td,
} from "@chakra-ui/react"
import {useCallback, useEffect, useRef, useState} from "react";

export default function App() {
  const [tabIndex, setTabIndex] = useState(0);
  const [time, setTime] = useState("");
  useEffect(() => {

    const intervalId = window.setInterval(() => {
      setTime(new Date().toLocaleString());
    }, 1000);
    return () => {
      window.clearInterval(intervalId);
    };
  }, []);
  return <ChakraProvider theme={theme}>
    <VStack minH="100vh"  fontSize="xl" bgColor="purple.100">
      <Box h="24px"/>
      <Heading>픽사톤(PIXATHON) 출품 현황</Heading>
      <Heading>{time}</Heading>
      <Box h="24px" />
      <HStack w="100%" h="100%" paddingBottom="24px" paddingX="24px">
        <Box boxShadow="2xl" borderRadius="xl" width="100%" maxW="800px" marginX="auto" backgroundColor="white">
          <Tabs onChange={(index) => setTabIndex(index)} isFitted variant='enclosed' colorScheme="purple">
            <TabList>
              <Tab>Video Ranking</Tab>
              <Tab>Motion Ranking</Tab>
            </TabList>
            <TabPanels>
              <TabPanel>
                <RankingMaker
                    on={tabIndex === 0}
                    url="https://api.content.pixt.is/content/pixathon"
                />
              </TabPanel>
              <TabPanel>
                <RankingMaker
                    on={tabIndex === 1}
                    url="https://api.content.pixt.is/content/pixathon?motion=true"
                />
              </TabPanel>
            </TabPanels>
          </Tabs>
        </Box>
      </HStack>
    </VStack>
  </ChakraProvider>;
}

type RankingMakerProps = {
  url: string;
  on: boolean;
};

function RankingMaker({ on, url }: RankingMakerProps) {
  const loading = useRef(false);
  const [items, setItems] = useState<RankingItem[]>([]);
  const fetchRank = useCallback(async () => {
    if (!on || loading.current) {
      return;
    }
    loading.current = true;
    type responseType = {
      nickname: string;
      totalCount: number;
    };
    const res = await fetch(url);
    const list = await res.json() as responseType[];
    setItems(list.map((value, index) => ({
      rank: index+1,
      nickname: value.nickname,
      count: value.totalCount,
    })));
    loading.current = false;
    setTimeout(fetchRank, 1000*60*30);
  }, [on, url]);

  useEffect(() => {
    fetchRank();
  }, [fetchRank]);
  return <Ranking items={items} />
}

type RankingProps = {
  items: RankingItem[];
};

type RankingItem = {
  rank: number;
  nickname: string;
  count: number;
};

function Ranking({ items }: RankingProps) {
  return <TableContainer>
    <Table variant='simple'>
      <TableCaption>랭킹</TableCaption>
      <Thead>
        <Tr>
          <Th>순위</Th>
          <Th>작가 닉네임</Th>
          <Th isNumeric>출품 갯수</Th>
        </Tr>
      </Thead>
      <Tbody>
        {
          items.map(value =>
              <Tr key={value.rank}>
                <Td>{value.rank}</Td>
                <Td>{value.nickname}</Td>
                <Td isNumeric>{value.count}</Td>
              </Tr>
          )
        }
      </Tbody>
    </Table>
  </TableContainer>;
}